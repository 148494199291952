const export_excel_file = (export_data, list_name) => {
  let link = document.createElement("a");
  //    type就是blob的type,是MIME类型的，可以自己查看MIME类型都有哪些
  let blogw = new Blob([export_data], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  let objectUrl = window.URL.createObjectURL(blogw); //创建一个新的url对象
  link.href = objectUrl;
  let file_name = `${list_name}列表.xlsx`;
  link.download = file_name; //  下载的时候自定义的文件名
  link.click();
  window.URL.revokeObjectURL(objectUrl); //为了更好地性能和内存使用状况，应该在适当的时候释放url.
};

// 时间转换格式
const format_time_date = (d) => {
  if (d !== null) {
    var date = new Date(d);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "-" + m + "-" + d;
    var hh = date.getHours();
    hh = hh < 10 ? "0" + hh : hh;
    var mm = date.getMinutes();
    mm = mm < 10 ? "0" + mm : mm;
    var ss = date.getSeconds();
    ss = ss < 10 ? "0" + ss : ss;
    var time = hh + ":" + mm + ":" + ss;
    return currentdate + " " + time;
  }
};

// 时间转换格式
const format_time_date_noTime = (d) => {
  if (d !== null) {
    var date = new Date(d);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var currentdate = y + "-" + m + "-" + d;
    return currentdate;
  }
};

// 日期格式转换--默认展示一周日期
const format_Date = (timestamp, formater) => {
  const date = new Date();
  date.setTime(parseInt(timestamp));
  //  传入formater形式 yyyy-MM-dd yyyy-MM-dd hh:mm
  formater = formater != null ? formater : "yyyy-MM-dd";
  const Format = function (fmt) {
    var o = {
      "M+": date.getMonth() + 1, // 月
      "d+": date.getDate(), // 日
      "h+": date.getHours(), // 小时
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };

    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  };
  return Format(formater);
};

// 开始时间-结束时间 展开中间天数
const show_date_format = (start, end) => {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let startStamp = startDate.getTime();
  let endStamp = endDate.getTime();
  let arr = [];
  for (
    let i = 0;
    i < Math.floor(Math.abs(startStamp - endStamp) / (1000 * 3600 * 24));
    i++
  ) {
    startDate.setTime(startStamp + 3600000 * 24 * i);
    let year = startDate.getFullYear();
    let month = startDate.getMonth() + 1;
    let day = startDate.getDate();
    year = "" + year;
    month = month < 10 ? "0" + month : "" + month;
    day = day < 10 ? "0" + day : "" + day;
    arr.push(`${year}-${month}-${day}`);
  }
  arr.push(end);
  return arr;
};

// excel下载文件名时间格式
const format_time_date_excel = (date) => {
  // if (typeof date == "string") {
  //   return format_time_date2(date);
  // }
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var mi = date.getMinutes();
  mi = mi < 10 ? "0" + mi : mi;
  var s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + m + "-" + d + " " + h + "-" + mi + "-" + s;
};

// 去掉数字末位0，比如80就输出8
const removeTrailingZeros = (num) => {
  // 将数字转换为字符串
  let numStr = num.toString();

  // 判断字符串是否以0结尾
  if (numStr.charAt(numStr.length - 1) === "0") {
    // 去掉末位0
    numStr = numStr.slice(0, -1);
  }
  if (numStr.charAt(numStr.length - 2) === "0") {
    numStr.replace(/00/g, "0");
  }

  // 将字符串转换为数字
  let result = parseFloat(numStr);
  return result;
};

export {
  export_excel_file,
  format_time_date,
  format_time_date_noTime,
  format_Date,
  show_date_format,
  format_time_date_excel,
  removeTrailingZeros,
};
