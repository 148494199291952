<template>
  <div class="today_data">
    <div class="header_title_agent">
      <p>今日数据</p>
      <span>{{ dateNow }}</span>
    </div>

    <div class="all_country">
      <p>全国</p>
      <div class="all_country_module">
        <div
          class="all_country_module_oneThem"
          v-for="(item, i) in todayDataModuleAll"
          :key="i"
        >
          <p class="all_country_module_oneThem_title">{{ item.title }}</p>
          <p style="font-size: 24px">{{ item.count }}</p>
        </div>
      </div>
    </div>

    <div>
      <div class="select_country">
        <el-dropdown trigger="click" @visible-change="handleCityList">
          <div class="select_down">
            <p>{{ cityName }}{{ cityNameValue }}</p>

            <img src="@/assets/agent/down.png" v-if="!cityNameShow" alt="" />
          </div>
          <el-dropdown-menu
            slot="dropdown"
            style="margin-top: 0; overflow-y: scroll"
            v-if="!cityNameShow"
          >
            <div v-for="item in cityList" :key="item.id">
              <el-dropdown-item @click.native="handleCommand(item)">{{
                item.name
              }}</el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="all_country_module">
        <div
          class="all_country_module_oneThem"
          v-for="(item, i) in todayDataModule"
          :key="i"
        >
          <p class="all_country_module_oneThem_title">{{ item.title }}</p>
          <p style="font-size: 24px">{{ item.count }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlCascader from "@/components/Cascader/cascader.vue";

import { reqUrl, reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: { AlCascader },
  data() {
    return {
      cityPid: "",
      provinceOptions: [],
      cityList: [],
      cityNameValue: "",
      cityNameShow: false,

      todayDataModuleAll: [
        { title: "新增实名", type: "realName", count: "" },
        { title: "新增车主", type: "driver", count: "" },
        { title: "乘客行程", type: "travelPassenger", count: "" },
        { title: "车主行程", type: "travelDriver", count: "" },
        { title: "成单订单", type: "orderCount", count: "" },
        { title: "活跃用户", type: "activeUser", count: "" },
      ],
      todayDataModule: [
        { title: "新增实名", type: "realName", count: "" },
        { title: "新增车主", type: "driver", count: "" },
        { title: "乘客行程", type: "travelPassenger", count: "" },
        { title: "车主行程", type: "travelDriver", count: "" },
        { title: "成单订单", type: "orderCount", count: "" },
        { title: "活跃用户", type: "activeUser", count: "" },
      ],
      cityName:
        "" || localStorage.getItem("city")
          ? localStorage.getItem("city")
          : localStorage.getItem("province"),

      dateNow: "",
      updateTime: "",
    };
  },

  mounted() {
    if (localStorage.getItem("city")) {
      this.cityNameShow = true;
    } else {
      this.cityNameShow = false;
    }
    this.dateNow = format_time_date(new Date().getTime());

    const nowDate = new Date();
    const date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate(),
    };
    const newmonth = date.month >= 9 ? date.month : "0" + date.month;
    const day = date.date >= 9 ? date.date : "0" + date.date;
    this.updateTime = date.year + "-" + newmonth + "-" + day;

    showLoading();
    const optList = {
      url: reqUrlAgent.AgentgetTodayCityCount,
      method: "POST",
      params: JSON.stringify({
        countDate: this.updateTime,
        country: localStorage.getItem("country"),
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          hideLoading();

          this.todayDataModuleAll.map((item) => {
            if (item.type == "realName") {
              item.count = res.data.data.realName;
            } else if (item.type == "driver") {
              item.count = res.data.data.driver;
            } else if (item.type == "travelDriver") {
              item.count = res.data.data.travelDriver;
            } else if (item.type == "travelPassenger") {
              item.count = res.data.data.travelPassenger;
            } else if (item.type == "orderCount") {
              item.count = res.data.data.orderCount;
            } else if (item.type == "activeUser") {
              item.count = res.data.data.activeUser;
            }
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optList);

    const optList2 = {
      url: reqUrlAgent.AgentgetTodayCityCount,
      method: "POST",
      params: JSON.stringify({
        countDate: this.updateTime,
        country: localStorage.getItem("country"),
        province: localStorage.getItem("province"),
        city: localStorage.getItem("city") || "",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          hideLoading();

          this.todayDataModule.map((item) => {
            if (item.type == "realName") {
              item.count = res.data.data.realName;
            } else if (item.type == "driver") {
              item.count = res.data.data.driver;
            } else if (item.type == "travelDriver") {
              item.count = res.data.data.travelDriver;
            } else if (item.type == "travelPassenger") {
              item.count = res.data.data.travelPassenger;
            } else if (item.type == "orderCount") {
              item.count = res.data.data.orderCount;
            } else if (item.type == "activeUser") {
              item.count = res.data.data.activeUser;
            }
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optList2);

    const opt = {
      url: reqUrl.getProvinceCity,
      method: "POST",
      params: JSON.stringify({
        pid: "0",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.data) {
          this.provinceOptions = res.data.data;

          this.provinceOptions.forEach((obj) => {
            if (obj.name === this.cityName) {
              this.cityPid = obj.id;
            }
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleCityList(e) {
      if (e) {
        const opt = {
          url: reqUrl.getProvinceCity,
          method: "POST",
          params: JSON.stringify({
            pid: this.cityPid,
          }),
          resFunc: (res) => {
            if (res.data.data) {
              this.cityList = res.data.data;
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleCommand(item) {
      this.cityNameValue = item.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1504px) {
  .all_country_module_oneThem {
    width: 190px !important;
  }
}
@media screen and (max-width: 1504px) {
  .all_country_module_oneThem {
    width: 180px !important;
  }
}
@media screen and (max-width: 1435px) {
  .all_country_module_oneThem {
    width: 170px !important;
  }
}
@media screen and (max-width: 1375px) {
  .all_country_module_oneThem {
    width: 160px !important;
  }
}
@media screen and (max-width: 1315px) {
  .all_country_module_oneThem {
    width: 230px !important;
  }
}
@media screen and (max-width: 1251px) {
  .all_country_module_oneThem {
    width: 220px !important;
  }
}
@media screen and (max-width: 1210px) {
  .all_country_module_oneThem {
    width: 210px !important;
  }
}
@media screen and (max-width: 1170px) {
  .all_country_module_oneThem {
    width: 200px !important;
  }
}
@media screen and (max-width: 1121px) {
  .all_country_module_oneThem {
    width: 190px !important;
  }
}
@media screen and (max-width: 1090px) {
  .all_country_module_oneThem {
    width: 180px !important;
  }
}
@media screen and (max-width: 1051px) {
  .all_country_module_oneThem {
    width: 170px !important;
  }
}
@media screen and (max-width: 1000px) {
  .all_country_module_oneThem {
    width: 160px !important;
  }
}
@media screen and (max-width: 971px) {
  .all_country_module_oneThem {
    width: 200px !important;
  }
}
@media screen and (max-width: 919px) {
  .all_country_module_oneThem {
    width: 190px !important;
  }
}
@media screen and (max-width: 888px) {
  .all_country_module_oneThem {
    width: 180px !important;
  }
}

.all_country_module {
  display: flex;
  flex-wrap: wrap;

  .all_country_module_oneThem {
    width: 192px;
    height: 110px;
    background: #fff;
    border-radius: 24px;
    padding: 24px 0 24px 24px;
    box-sizing: border-box;
    margin: 0 12px 12px 0;

    .all_country_module_oneThem_title {
      font-size: 14px;
      color: #4e5259;
      margin-bottom: 12px;
    }
  }
}

.today_data {
  width: 100%;
  z-index: 1;

  .all_country {
    margin-bottom: 10px;

    p {
      font-size: 16px;
      color: #202226;
      margin-bottom: 12px;
    }
  }

  .select_country {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      font-size: 16px;
      color: #202226;
      margin-right: 5px;
    }

    .select_down {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 16px;
      }
    }
  }
}
</style>
